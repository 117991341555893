<template>
	<div class="flex flex-col gap-8">
		<div :class="['grid gap-4 md:gap-8 relative', cols]">
			<v-loader v-if="loading"></v-loader>
			<template v-if="items.length">
				<template v-for="(item, index) in items">
					<slot name="item" :value="item"></slot :key="`document-${index}-${_uid}`">
				</template>
			</template>
			<div v-else :class="columns == 4 ? 'col-span-4' : 'col-span-3'" class="rounded-md bg-stone-100 flex p-10 text-lg font-semibold min-h-[200px]">
				<span class="m-auto">No hay documentos registrados</span>
			</div>
		</div>
		<VPagination class="mx-auto" :pages="pagination.pages" v-model="list.current"></VPagination>
		<VPanelVideos v-if="modulo == 'videos'" :endpoint="endpoint" v-model="id" :lang="lang"></VPanelVideos>
	</div>
</template>
<script>
import VPagination from "../VPagination/VPagination.vue"
import VPanelVideos from "../VPanelVideos/VPanelVideos.vue"
export default {
  name: 'VGrid',
  components: {
  	VPagination,
		VPanelVideos
  },
	props: {
		lang: {
      type: String,
      default: "es"
    },
		modulo: {
			type: String,
			default: "documents"
		},
		columns: {
			type: [String, Number],
			default: 3,
		},
		perPage: {
			type: Number,
			default: 9
		},
		endpoint: {
			type: String,
			default: ""
		}
	},
	provide(){
		return {
			onSetVideo: (id) => {
				this.id = id;
			}
		}
	},
  data() {
    return {
    	list: {
				current: 1
			},
			id: null
    }
  },
  computed: {
		cols(){
			switch(this.columns){
				case 4:
					return "grid-cols-2 md:grid-cols-3 lg:grid-cols-4";
				default:
					return "grid-cols-2 lg:grid-cols-3";
			}
		},
  	loading(){
  		return this.$store.state[this.modulo].loading;
  	},
  	elements(){
  		this.list.current = 1;
  		return this.$store.state[this.modulo].grid;
  	},
  	items(){
  		return this.elements.length ? this.elements.slice(this.pagination.start, this.pagination.end) : [];
  	},
  	pagination(){
			let start = (this.list.current - 1)   * this.perPage;
			let end = start + this.perPage;
			let pages = Math.ceil(this.elements.length / this.perPage);
			return  {
				pages,
				start, 
				end
			}
		}
  }
}
</script>