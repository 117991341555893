<template>
  <section class="text-neutral-90 flex flex-col gap-[64px]">
    <!-- Map Filters -->
    <div
      v-if="!noFilters"
      class="map-filters flex flex-col sm:flex-row gap-4 justify-around"
    >
      <div v-if="!multiMaps" class="map-filters_filter">
        <custom-select
          v-model="select.country.value"
          :first="{ text: lang == 'es' ? 'Todo' : 'All', value: null }"
          :placeholder="lang == 'es' ? 'Todo' : 'All'"
          :choices="select.country.options"
          :name="`mapCountry-${_uid}`"
          :label="microcopyCountryFilter"
        />
      </div>
      <!-- <div class="map-filters_filter">
        <custom-select
          v-model="select.state.value"
          :choices="select.state.options"
          :name="`mapDepartment-${_uid}`"
          :label="microcopyDepartmentFilter"
        />
      </div> -->
      <div class="map-filters_filter">
        <custom-select
          v-model="select.city.value"
          :first="{ text: lang == 'es' ? 'Todo' : 'All', value: null }"
          :placeholder="lang == 'es' ? 'Todo' : 'All'"
          :choices="select.city.options"
          :name="`mapCity-${_uid}`"
          :label="microcopyCityFilter"
        />
      </div>
      <!-- Select solo para multimapas -->
      <div v-if="multiMaps" class="map-filters_filter">
        <custom-select
          v-model="service.value"
          :choices="service.options"
          :name="`mapService-${_uid}`"
          :label="microcopyServiceFilter"
        />
      </div>
    </div>
    <!-- Map - custom -->
    <div class="mapViewer">
      <div class="mapViewer-map">
        <gmap-map
          v-bind="map"
          style="
            width: 100%;
            height: 515px;
            overflow: hidden;
            border-radius: 16px;
          "
          ref="mapMain"
        >
          <gmap-info-window
            v-bind="infoWindow"
            @closeclick="infoWindow.opened = false"
          />
          <GmapMarker
            v-for="(marker, index) in markers"
            :key="index"
            :position="marker.position"
            :icon="iconMark(marker.position)"
            :clickable="true"
            @click="openInfoWindow(marker.items)"
          />
          <GmapMarker
            v-if="user"
            :position="user.position"
            :icon="userIcon"
            :clickable="true"
          />
        </gmap-map>
      </div>
      <div class="mapViewer-content">
        <div class="mapViewer-content__header">
          <i
            class="mapViewer-content__header--icon"
            v-icon:[contentIconLocal]
          ></i>
          <h3 class="mapViewer-content__header--title title is-5">
            {{ contentTitleView }}
          </h3>
        </div>
        <div class="mapViewer-content__body">
          <!-- loading -->
          <div v-show="loading" role="status" class="flex justify-center">
            <svg
              class="inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-primary-0"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <!-- message - alert -->
          <v-message
            v-show="alertMessage"
            type="error"
            :message="alertMessage"
          />
          <!-- Content - success -->
          <ul class="mapViewer-content__body--list">
            <li v-for="(item, index) in list" :key="index">
              <span @click="openInfoWindow(item)">{{ item.nom }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import { loadGmapApi, gmapApi } from "vue2-google-maps";
export default {
  name: "VMap",
  props: {
    contentIcon: {
      type: String,
      default: "gasoline",
    },
    contentTitle: {
      type: String,
      default: "",
    },
    filterRelationTitle: {
      type: String,
      default: "",
    },
    zoom: {
      type: Number,
      default: 6.3,
    },
    mapIcon: {
      type: String,
      default:
        "data:image/svg+xml,%3Csvg width='17.54' height='20.95' viewBox='241.407 239.844 17.186 21.093' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 241.904 249.524 C 242.539 250.794 244.888 254.73 249.206 260.318 L 251.428 258.096 L 254.603 254.603 L 257.461 250.476 L 258.096 247.936 L 257.778 245.714 L 256.826 243.174 L 254.603 241.269 L 252.698 239.999 L 250.158 239.682 L 247.619 240.317 L 245.396 241.269 L 243.809 242.857 L 242.221 245.079 L 241.904 249.524 Z' fill='white' fill-opacity='0.8' style=''/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M 244.911 243.149 C 247.721 240.379 252.278 240.379 255.089 243.149 C 257.9 245.918 257.9 250.408 255.089 253.177 C 255.078 253.187 255.068 253.198 255.058 253.209 L 249.413 259.446 L 250.586 260.477 L 256.216 254.256 C 259.625 250.881 259.619 245.422 256.2 242.054 C 252.775 238.679 247.224 238.679 243.799 242.054 C 240.387 245.416 240.375 250.859 243.763 254.236 L 246.051 257.157 L 247.295 256.211 L 244.977 253.251 C 244.956 253.225 244.934 253.2 244.91 253.177 C 242.1 250.408 242.1 245.918 244.911 243.149 Z M 250 249.769 C 250.953 249.769 251.726 249.008 251.726 248.068 C 251.726 247.129 250.953 246.367 250 246.367 C 249.046 246.367 248.273 247.129 248.273 248.068 C 248.273 249.008 249.046 249.769 250 249.769 Z M 253.298 248.068 C 253.298 249.863 251.821 251.318 250 251.318 C 248.178 251.318 246.701 249.863 246.701 248.068 C 246.701 246.273 248.178 244.818 250 244.818 C 251.821 244.818 253.298 246.273 253.298 248.068 Z' fill='%23DF1A09'/%3E%3C/svg%3E",
    },
    userIcon: {
      type: String,
      default:
        "data:image/svg+xml,%3Csvg width='17.54' height='20.95' viewBox='241.407 239.844 17.186 21.093' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 241.904 249.524 C 242.539 250.794 244.888 254.73 249.206 260.318 L 251.428 258.096 L 254.603 254.603 L 257.461 250.476 L 258.096 247.936 L 257.778 245.714 L 256.826 243.174 L 254.603 241.269 L 252.698 239.999 L 250.158 239.682 L 247.619 240.317 L 245.396 241.269 L 243.809 242.857 L 242.221 245.079 L 241.904 249.524 Z' fill='white' fill-opacity='0.8' style=''/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M 244.911 243.149 C 247.721 240.379 252.278 240.379 255.089 243.149 C 257.9 245.918 257.9 250.408 255.089 253.177 C 255.078 253.187 255.068 253.198 255.058 253.209 L 249.413 259.446 L 250.586 260.477 L 256.216 254.256 C 259.625 250.881 259.619 245.422 256.2 242.054 C 252.775 238.679 247.224 238.679 243.799 242.054 C 240.387 245.416 240.375 250.859 243.763 254.236 L 246.051 257.157 L 247.295 256.211 L 244.977 253.251 C 244.956 253.225 244.934 253.2 244.91 253.177 C 242.1 250.408 242.1 245.918 244.911 243.149 Z M 250 249.769 C 250.953 249.769 251.726 249.008 251.726 248.068 C 251.726 247.129 250.953 246.367 250 246.367 C 249.046 246.367 248.273 247.129 248.273 248.068 C 248.273 249.008 249.046 249.769 250 249.769 Z M 253.298 248.068 C 253.298 249.863 251.821 251.318 250 251.318 C 248.178 251.318 246.701 249.863 246.701 248.068 C 246.701 246.273 248.178 244.818 250 244.818 C 251.821 244.818 253.298 246.273 253.298 248.068 Z' fill='%23008000'/%3E%3C/svg%3E",
    },
    mapType: {
      type: String,
      default: "businesses",
    },
    multiMaps: {
      type: Boolean,
      default: false,
    },
    mapFilters: {
      type: Array,
      default: () => [],
    },
    endpointBase: {
      type: String,
      required: true,
    },
    noFilters: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Array,
      default: () => [],
    },
    apiMapKey: {
      type: String,
      default: "",
    },
    microcopyCountryFilter: {
      type: String,
      default: "País",
    },
    microcopyDepartmentFilter: {
      type: String,
      default: "Departamento",
    },
    microcopyCityFilter: {
      type: String,
      default: "Ciudad",
    },
    microcopyServiceFilter: {
      type: String,
      default: "Servicio",
    },
    msgNotExistRecord: {
      type: String,
      default: "No existen registros!",
    },
    lang: {
      type: String,
      default: "es",
    },
  },
  data() {
    return {
      contentIconLocal: this.contentIcon,
      contentTitleView: this.contentTitle,
      loading: true,
      select: {
        country: {
          options: [],
          data: [],
          zoom: 6,
          value: null,
        },
        // state: {
        //   options: [],
        //   data: [],
        //   zoom: 7,
        //   value: null
        // },
        city: {
          options: [],
          data: [],
          zoom: 8,
          value: null,
        },
      },
      service: {
        options: this.services.map(({ value, ...s }, index) => ({
          value: `${value}_${index}`,
          ...s,
        })),
        data: [],
        value: null,
      },
      map: {
        key: `${Date.now()}_${this.uid}`,
        zoom: this.zoom,
        center: {
          lat: 7.9484338,
          lng: -74.5225033,
        },
        options: { disableDefaultUI: true },
      },
      infoWindow: {
        options: {
          content: "",
        },
        position: {
          lat: 0,
          lng: 0,
        },
        opened: false,
      },
      options: [],
      city: [],
      markers: [],
      list: [],
      alertMessage: null,
      user: null,
    };
  },
  computed: {
    ...mapState(["Map"]),
  },
  watch: {
    "Map.wLoad"(a) {
      if (a) this.getDataMap(this.mapType, this.mapFilters);
    },
    "Map.user"(a) {
      this.user = a;
    },
    "service.value"(a) {
      const mapType = a.split("_")[0];
      const mapFilters = [];
      this.service.options.forEach((filter) => {
        if (filter.value == a) {
          this.contentIconLocal = filter.icon;
          this.contentTitleView = filter.text;
          const fl = {
            tip: filter.filterText.includes("familyCode")
              ? filter.filterText.split("-")[1]
              : filter.filterText,
          };
          if (filter.hasOwnProperty("typ"));
          fl.typ = filter.typ;
          mapFilters.push(fl);
        }
      });

      this.getDataMap(mapType, mapFilters);
    },
  },
  mounted() {
    this.initMap();

    if (this.Map.wLoad) this.getDataMap(this.mapType, this.mapFilters);

    for (let prop in this.select) {
      const keys = Object.keys(this.select);
      const index = keys.indexOf(prop);
      const next = keys.length > index ? keys[index + 1] : null;
      const prev = index > 0 ? keys[index - 1] : null;

      this.$watch(
        () => this.select[prop].value,
        (a) => {
          const value = a?.toLowerCase() || a;
          const result = this.select[prop].data.find((i) => {
            return i.text.toLowerCase() == value;
          });

          if (result) {
            Object.assign(this.map, {
              center: {
                lat: result?.lat,
                lng: result?.lng,
              },
              zoom: this.select[prop].zoom,
            });
          }

          if (next) {
            for (let el of keys.slice(index + 1)) {
              const list = [];
              if (value) {
                this.select[el].data.forEach((item) => {
                  if (item[prop] && value == item[prop].toLowerCase())
                    list.push(this.mapOptionsSelect(item.text));
                });
                this.select[el].options = list;
              }
              this.select[el].value = null;
            }
          }

          let resList = null;
          if (value) {
            resList = this.options.filter((opt) => {
              const res = [];

              if (prop == "country") res.push(opt.pai.toLowerCase() == value);

              if (prop == "state") res.push(opt.dep.toLowerCase() == value);

              if (prop == "city") res.push(opt.ciu.toLowerCase() == value);

              return !res.includes(false);
            });

            this.list = resList;
            this.markers = this.setMarkesMap(resList);
          } else {
            if (prev) {
              const preValue = this.select[prev].value?.toLowerCase();
              resList = this.options.filter((opt) => {
                const res = [];
                if (prev == "country")
                  res.push(preValue ? opt.pai.toLowerCase() == preValue : true);

                if (prev == "state")
                  res.push(preValue ? opt.dep.toLowerCase() == preValue : true);

                if (prev == "city")
                  res.push(preValue ? opt.ciu.toLowerCase() == preValue : true);

                return !res.includes(false);
              });
              console.log(preValue);
            } else if (!index) {
              resList = this.options.filter(() => true);
              for (let p in this.select) {
                if (this[p]) this.select[p].options = this[p];
              }
            }
          }

          if (resList.length) this.list = resList;

          this.markers = this.setMarkesMap(resList);

          this.infoWindow.opened = false;
        }
      );
    }
  },
  methods: {
    iconMark({ lat: la, lng: ln }) {
      return this.mapIcon;
    },
    async getDataMap(mapType, mapFilters) {
      this.options = [];
      this.list = [];
      this.markers = [];
      this.$store.dispatch("Map/getCurrentPosition");

      const { endpointBase } = this;
      this.loading = true;
      const { message, options } = await this.$store.dispatch(
        "Map/getListMap",
        { endpointBase, mapType }
      );
      if (message) {
        this.alertMessage = message;
      } else {
        const select = {
          country: {
            data: [],
            options: [],
          },
          state: {
            data: [],
            options: [],
          },
          city: {
            data: [],
            options: [],
          },
        };

        const list = options.filter((item) => {
          const result = { tip: null, typ: null };
          mapFilters.forEach((filter) => {
            result.tip = filter.tip.toLowerCase();
            if (filter.typ) result.typ = filter.typ.toLowerCase();
          });
          const tipValue = item?.price
            ? [
                item?.tip?.toLowerCase(),
                ...item?.price.map(({ id }) => id?.toLowerCase()),
              ]
            : [item?.tip?.toLowerCase()];
          const paiValue = item?.pai?.toLowerCase();
          const depValue = item?.dep?.toLowerCase();
          const ciuValue = item?.ciu?.toLowerCase();
          /*const typValue = result.typ
            ? result.typ == item?.typ?.toLowerCase()
            : true;*/
          const typValue = true;
          const estValue = item.hasOwnProperty("est")
            ? item?.est?.toLowerCase() == "abierto"
            : true;

          if (
            tipValue.includes(result.tip) &&
            typValue && 
            estValue &&
            paiValue &&
            depValue &&
            ciuValue
          ) {
            const pai = select.country.options.find((p) => {
              return p?.value?.toLowerCase() == paiValue;
            });

            const dep = select.state.options.find((p) => {
              return p?.value?.toLowerCase() == depValue;
            });

            const ciu = select.city.options.find((p) => {
              return p?.value?.toLowerCase() == ciuValue;
            });

            const paiText = this.textCapitalize(paiValue);
            const depText = this.textCapitalize(depValue);
            const ciuText = this.textCapitalize(ciuValue);

            if (!pai) {
              select.country.options.push(this.mapOptionsSelect(paiText));

              select.country.data.push({
                text: paiText,
                lat: item.lat,
                lng: item.lon,
              });
            }

            if (!dep) {
              select.state.options.push(this.mapOptionsSelect(depText));

              select.state.data.push({
                text: depText,
                country: paiText,
                lat: item.lat,
                lng: item.lon,
              });
            }

            if (!ciu) {
              this.city.push(this.mapOptionsSelect(ciuText));
              select.city.options.push(this.mapOptionsSelect(ciuText));

              select.city.data.push({
                text: ciuText,
                country: paiText,
                state: depText,
                lat: item.lat,
                lng: item.lon,
              });
            }

            return true;
          } else {
            return false;
          }
        });

        for (let prop in this.select) {
          select[prop].options.sort((a, b) =>
            a.text > b.text ? 1 : a.text < b.text ? -1 : 0
          );
          select[prop].data.sort((a, b) =>
            a.text > b.text ? 1 : a.text < b.text ? -1 : 0
          );

          if (this[prop])
            this[prop].sort((a, b) =>
              a.text > b.text ? 1 : a.text < b.text ? -1 : 0
            );

          Object.assign(this.select[prop], select[prop]);
        }

        this.options = list;
        this.list = list;
        this.markers = this.setMarkesMap(list);
      }
      this.loading = false;
    },
    setMarkesMap(list) {
      return list.map((items) => ({
        items,
        position: {
          lat: items.lat,
          lng: items.lon,
        },
      }));
    },
    async initMap() {
      if (!this.Map.loadedMap) {
        loadGmapApi({
          key: this.apiMapKey,
          libraries: "places",
        });
        this.mapFilters;
        this.$store.commit("Map/SET_STATE", { prop: "loadedMap", value: true });
      }
    },
    mapOptionsSelect(value) {
      return {
        text: value,
        value,
      };
    },
    async openInfoWindow(marker) {
      this.infoWindow.opened = false;
      const position = {
        lat: marker.lat,
        lng: marker.lon,
      };
      const dist = await this.$store.dispatch("Map/getGeolocation", {
        marker: { position },
      });
      this.infoWindow.options.content = this.contentInfoWindow(marker, dist);
      this.infoWindow.position = position;
      this.infoWindow.opened = true;
      this.map.zoom = 12;
      this.map.center = position;
    },
    contentInfoWindow(info, dist) {
      let tel = info.tel
        ? `
        <div class="markerInfo-footer__info">
          <i class="markerInfo-footer__info--icon phone"></i>
          <span class="markerInfo-footer__info--text">${info.tel}
        </div>
      `
        : "";

      let tie = info.tie
        ? `
        <div class="markerInfo-footer__info">
          <i class="markerInfo-footer__info--icon clock"></i>
          <span class="markerInfo-footer__info--text">${info.tie}</span>
        </div>
      `
        : "";

      let distance = dist
        ? `
        <p class="markerInfo-distance">${dist}</p>
      `
        : "";

      return `
        <div class="markerInfo">
          <span class="arrow"></span>
          <h5 class="markerInfo-title">${info.nom}</h5>
          <div class="markerInfo-body">
            <p class="markerInfo-description">${info.dir}</p>
            ${distance}
          </div>
          <div class="markerInfo-footer">
            ${tel}
            ${tie}
          </div>
        </div>
      `;
    },
    textCapitalize(text) {
      return text
        ? text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
        : null;
    },
  },
};
</script>
